@import '@scss/typography.scss';
@import '@scss/common.scss';

.modal {
  max-width: 900px;
  background-color: var(--primary-white);

  &:not(.visible) {
    opacity: 0;
  }
}

.formBlockList {
  counter-reset: section;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .formBlock {
    position: relative;

    .formBlockTitle {
      padding-left: 32px;
      line-height: 24px;
    }

    &::before {
      @extend .body_s;
      position: absolute;
      left: 0;
      top: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--primary-dark);
      color: var(--primary-white);
      counter-increment: section;
      content: counter(section);
    }
  }

  @media screen and (min-width: 1024px) {
    gap: 40px;
  }
}

.modalBg {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 149;
  overflow-y: hidden;
}

.formBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formBlockTitle {
  @extend .other_btn_uppercase;
  margin-bottom: 24px;
}

.blockWrapper {
  display: grid;
  gap: 16px;

  @media screen and (min-width: 620px) {
    grid-template-columns: repeat(2, 260px);
    gap: 14px 20px;
    align-items: flex-start;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 260px);
  }
}

.saveBtn {
  width: 100%;
  text-transform: uppercase;
}

.inputBig {
  max-width: 100%;
  margin-top: 15px;
  padding-bottom: 0;
}

.error_message {
  margin-left: 2px;
  color: var(--system-error);
  font-size: 13px;
  line-height: 1.2;
  margin-top: 3px;
}

.priceBlock {
  margin-top: 9px;
  display: flex;
  gap: 6px;
}

.additionalPayMethodBlock,
.paymentBlock {
  display: grid;
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .additionalPayMethodBlock,
  .paymentBlock {
    grid-template-columns: repeat(3, 260px);
    align-items: flex-start;
  }

  .inputBig {
    max-width: 48%;
    margin-right: 20px;
    width: 100%;
    &.inputAddress {
      max-width: 100%;
    }

    &:nth-child(2n) {
      margin-right: 0px;
    }
  }

  .modalBg {
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .modalBlock {
    margin: 0 30px;
  }

  .modalHeader {
    padding: 14px 50px;
  }

  .form {
    padding: 0px 50px;
    max-height: 68vh;
  }

  .btnBlock {
    padding: 14px 50px;
  }

  .saveBtn {
    width: 260px;
  }
}

@media screen and (min-width: 1281px) {
  .infoBtn {
    top: 26px;
  }
}
